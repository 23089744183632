
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Over18Modal from "@/components/Over18Modal.vue";
import Cookie from "@/components/Cookie.vue";

export interface NavbarLink {
  text: string;
  to: string;
  link?: string;
}

const navbars: NavbarLink[] = [
  { text: "» Shopsuche", to: "/shop" },
  { text: "» Über mich", to: "/about-me" },
  { text: "» Die Süss Saure", to: "/suess-saure" },
  { text: "» Schreib uns", to: "/write-us" },
];

const footers: NavbarLink[] = [
  { text: "Impressum", to: "/legal-notice" },
  { text: "Datenschutz", to: "/data-protection" },
];

export default defineComponent({
  name: "App",
  components: {
    Navbar,
    Footer,
    Over18Modal,
    Cookie
  },
  setup() {
    return {
      footers,
      navbars,
    };
  },
});
