import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag-next";
import { gtagConfig } from "./config";

const app = createApp(App);

app.use(VueGtag, gtagConfig);
app.use(store);
app.use(router);
app.mount("#app");
