
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "isOver18",
  setup() {
    const store = useStore();
    const route = useRoute();
    const root = ref();
    let modal: Modal;

    const isOver18 = computed(() => store.state.isOver18);
    const path = computed(() => route.path);

    function changeIsOver18(is: boolean) {
      store.commit("isOver18", is);
    }

    onMounted(() => {
      modal = new Modal(root.value);
    });

    watch(isOver18, () => {
      modal.hide();
    });

    watch(route, () => {
      if (isOver18.value === false && path.value !== "/not-legal") modal.show();
      else modal.hide();
    });

    return {
      changeIsOver18,
      root,
    };
  },
});
