
import { defineComponent } from "vue";
import { useState, isTracking } from "vue-gtag-next";

export default defineComponent({
  setup() {
    const { isEnabled } = useState();
    const cookieConsentLocalStorageName = "cookie-consent";

    function getCookieConsent() {
      const value = localStorage.getItem(cookieConsentLocalStorageName);
      if (value === null) {
        enableGTag(false);
        return true;
      } else if (value === "only-necessary") {
        enableGTag(false);
        return false;
      } else {
        enableGTag(true);
        return false;
      }
    }

    function rememberCookieConsent(onlyNecessary: boolean) {
      if (onlyNecessary) {
        enableGTag(false);
        localStorage.setItem(cookieConsentLocalStorageName, "only-necessary");
      } else {
        enableGTag(true);
        localStorage.setItem(cookieConsentLocalStorageName, "full-consent");
      }
    }

    function enableGTag(enable: boolean) {
      //@ts-ignore
      isEnabled.value = enable;
    }

    return {
      rememberCookieConsent,
      getCookieConsent,
      isTracking,
    };
  },
});
