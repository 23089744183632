
import { defineComponent } from "vue";
import { isChristmas } from "@/utils";

export default defineComponent({
  data() {
    return {
      isChristmas,
    };
  },
});
