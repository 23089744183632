import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-auto py-3 bg-dark" }
const _hoisted_2 = { class: "nav justify-content-center raleway-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footers, (footer) => {
        return (_openBlock(), _createElementBlock("li", {
          key: footer.to,
          class: "nav-item"
        }, [
          _createVNode(_component_router_link, {
            class: "nav-link active px-2",
            to: footer.to
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(footer.text), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ]))
}