
import { NavbarLink } from "@/App.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Footer",
  props: {
    footers: {
      type: Array as PropType<NavbarLink[]>,
      required: true,
    },
  },
});
