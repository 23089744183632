import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Over18Modal = _resolveComponent("Over18Modal")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Cookie = _resolveComponent("Cookie")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Over18Modal),
    _createVNode(_component_Navbar, { navbars: _ctx.navbars }, null, 8, ["navbars"]),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer, { footers: _ctx.footers }, null, 8, ["footers"]),
    _createVNode(_component_Cookie)
  ], 64))
}