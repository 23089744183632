import { trackRouter } from "vue-gtag-next";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/shop",
    name: "Shop",
    component: () => import(/* webpackChunkName: "shop" */ "../views/Shop.vue"),
  },
  {
    path: "/about-me",
    name: "AboutMe",
    component: () =>
      import(/* webpackChunkName: "about-me" */ "../views/AboutMe.vue"),
  },
  {
    path: "/suess-saure",
    name: "SuessSaure",
    component: () =>
      import(/* webpackChunkName: "suess-saure" */ "../views/SuessSaure.vue"),
  },
  {
    path: "/write-us",
    name: "WriteUs",
    component: () =>
      import(/* webpackChunkName: "write-us" */ "../views/WriteUs.vue"),
  },
  {
    path: "/data-protection",
    name: "DataProtection",
    component: () =>
      import(/* webpackChunkName: "data-protection" */ "../views/DataProtection.vue"),
  },
  {
    path: "/not-legal",
    name: "NotLegal",
    component: () =>
      import(/* webpackChunkName: "not-legal" */ "../views/NotLegal.vue"),
  },
  {
    path: "/legal-notice",
    name: "LegalNotice",
    component: () =>
      import(/* webpackChunkName: "legal-notice" */ "../views/LegalNotice.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

trackRouter(router);

export default router;
